import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from 'primeng/api';

registerLocaleData(es);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  exports: [
    SharedModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-*',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: '',
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
